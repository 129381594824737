import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'
import { GatsbyImage as Image } from 'gatsby-plugin-image'

import Marquee from 'react-fast-marquee'

// We need at least ten images for this to look right on all screens.
const GalleryGrid = styled.div`
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: center;
`

const GalleryCell = styled(Image)`
  ${mq({
    height: ['75vw', '60vw', '55vw', '45vw', '35vw'],
    margin: ['10px', '10px', '10px', '15px', '15px'],
  })};
  position: relative;
  aspect-ratio: 9 / 16;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;:
`

function Gallery() {
  const pageQuery = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "carousel1.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 0.5625
            height: 1024
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      image2: file(relativePath: { eq: "carousel2.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 0.5625
            height: 1024
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      image3: file(relativePath: { eq: "carousel3.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 0.5625
            height: 1024
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      image4: file(relativePath: { eq: "carousel4.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 0.5625
            height: 1024
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      image5: file(relativePath: { eq: "carousel5.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 0.5625
            height: 1024
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      image6: file(relativePath: { eq: "carousel6.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 0.5625
            height: 1024
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
      image7: file(relativePath: { eq: "carousel7.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 0.5625
            height: 1024
            transformOptions: { cropFocus: CENTER }
          )
        }
      }
    }
  `)

  // Need at least ten for this to look decent at all screen sizes.
  const images = [
    pageQuery.image1.childImageSharp.gatsbyImageData,
    pageQuery.image5.childImageSharp.gatsbyImageData,
    pageQuery.image7.childImageSharp.gatsbyImageData,
    pageQuery.image2.childImageSharp.gatsbyImageData,
    pageQuery.image6.childImageSharp.gatsbyImageData,
    pageQuery.image3.childImageSharp.gatsbyImageData,
    pageQuery.image4.childImageSharp.gatsbyImageData,
  ]

  return (
    <Marquee gradient={false} speed={35}>
      <GalleryGrid>
        {images.map((image, index) => (
          <GalleryCell key={index} image={image}></GalleryCell>
        ))}
      </GalleryGrid>
    </Marquee>
  )
}

export default Gallery
