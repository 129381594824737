import React from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import Typewriter from 'typewriter-effect'
import { Link } from 'gatsby'

import { Mono } from './Typography'

const fadeIn = keyframes`
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
`

const TerminalWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  height: auto;
  background-color: var(--terminal-500);
  padding: 24px 24px 0 24px;
  box-shadow: 0px 60px 80px rgba(0, 0, 0, 0.2),
    0px 24px 36px rgba(0, 0, 0, 0.16), 0px 4px 8px rgba(0, 0, 0, 0.12);
`

const TerminalButtons = styled.div`
  float: left;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: var(--terminal-500);
  border: 1px solid var(--terminal-300);
`

const TerminalPrompt = styled.p`
  ${Mono};
  color: var(--firewall-300);
  display: inline-block;
  margin-block-start: 0;
  margin-block-end: 0;
  animation: ${fadeIn} ${props => props.delay}s;
  animation-fill-mode: forwards;
  visibility: ${props => (props.delay ? 'hidden' : 'visible')};
`

const TerminalBody = styled.p`
  ${Mono};
  color: var(--terminal-100);
  display: inline-block;
  margin-block-start: 0;
  margin-block-end: 0;
  animation: ${fadeIn} ${props => props.delay}s;
  animation-fill-mode: forwards;
  visibility: hidden;
`

const TypewriterWrapper = styled.div`
  ${Mono};
  color: var(--terminal-0);
  display: inline-block;
  padding-left: 12px;
  margin-block-start: 0;
  margin-block-end: 0;
`

const TerminalLink = styled(Link)`
  ${Mono};
  color: var(--screen-300);
  text-decoration: none;
  display: block;

  :first-of-type {
    margin-top: 36px;
  }

  :last-of-type {
    margin-bottom: 40px;
  }
`

const JobsTerminal = () => {
  return (
    <React.Fragment>
      <TerminalWrapper>
        <TerminalButtons />
        <TerminalButtons />
        <TerminalButtons />
        <br />
        <TerminalPrompt>→ ~</TerminalPrompt>
        <TypewriterWrapper>
          <Typewriter
            options={{ delay: 50 }}
            onInit={typewriter => {
              typewriter
                .callFunction(state => {
                  state.elements.cursor.style.display = 'none'
                })
                .typeString('supergood -jobs')
                .start()
            }}
          />
        </TypewriterWrapper>
        <TerminalBody delay={1.5}>
          View our current job openings below. If you don't see anything that
          suits you, but still think you'd be a great addition to our team, be
          in touch!
          <TerminalLink to="#">
            Sorry! We don't have any job openings right now.
          </TerminalLink>
        </TerminalBody>
      </TerminalWrapper>
    </React.Fragment>
  )
}

export default JobsTerminal
